.form {
    display: flex;
    flex-direction: column;
    width: 343px;
    margin : 40px auto;
}

.form label{
 color: rgba(0,0,0,0.3);
 margin: 5px;
 font-weight: 600;
 font-size: 1.1rem;
}
.form input {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin-bottom: 20px;
    width: 329px;
    height: 47px;
    padding: 2px 12px;
    font-size: 17px;
}

.form input:focus{
    outline: 1px solid #684AFF;
}



.tab {
    border: none;
    padding: 6px 8px;
    margin : 4px 20px ;
    color:rgba(0, 0, 0, 0.3);
    font-size: 1.2rem;
    font-weight: 700;
}

.active{    
color:#684AFF;
position: relative;
}
.active:before{
    content: "";
    position: absolute;
    left:18%;
    bottom:0;
    width: 70%;
    border-bottom: 3px solid #684AFF;
}

.right-link{
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  padding-right: 8px;
}

.circle{
    border-radius: 50%;
    background-color: #E4DFFF;
    width: 70px;
    height: 70px;
    margin: 20px auto ;
   display: grid;
   place-items: center;
}